import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core'
import {
  EditOutlined as EditOutlinedIcon,
  Error as ErrorIcon,
  Info as InfoIcon
} from '@material-ui/icons'
import clsx from 'clsx'
import { useFormikContext } from 'formik'
import { CustomizedTooltip, NumericField } from 'shared'
import { loadDistances, updateDistance } from 'state/modules/events'
import { showSnackbarSuccess } from 'utils/snackbar'
import * as Yup from 'yup'

import { ActionDialog } from '../ActionDialog'
import { EmptyCategoriesNotifyDialog } from '../EmptyCategoriesNotifyDialog'

import { useStyles } from './EditDistanceDialog.style'

const generateValidation = (categories) => {
  const keys = Object.keys(categories).filter((key) => Boolean(categories[key]))

  const schema = keys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: Yup.object().shape({
        quota: Yup.number()
          .typeError('Ingrese un número válido')
          .integer('Ingrese un número entero')
          .max(99999999, 'Se puede ingresar hasta 8 dígitos')
          .test(
            'test-negative-quota',
            'El cupo no puede ser un número negativo, dejarlo en blanco o cero (Sin límite)',
            (value = 0) => +value >= 0
          )
          .test('test-min-quota', 'error', (value = 0, { createError, parent }) => {
            const hasError = value !== 0 && value < parent.inscriptionsCount

            return (
              !hasError ||
              createError({
                message: `El cupo debe ser mayor o igual al número de inscriptos (${parent.inscriptionsCount})`
              })
            )
          })
      })
    }),
    {}
  )

  return schema
}

const OPTIONS = {
  Category: { label: 'categorías', key: 'Category' },
  Distance: { label: 'distancias', key: 'Distance' }
}

const DIALOG_STATES = {
  DIALOG_FORM: 'dialogForm',
  DIALOG_CONFIRM: 'dialogConfirm',
  DIALOG_NOTIFY: 'dialogNotify',
  DIALOG_UNBIND_NOTIFY: 'dialogUnbindNotify'
}

const EditDistanceDialog = ({
  allowCategoriesInMultipleDistances,
  selectedDistance,
  asyncError,
  eventId,
  items,
  showDialog,
  onClose,
  ticketTypes,
  duplicateMode
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { categories, updateDistancePending } = useSelector((state) => state.events)
  const { values: formState, setValues } = useFormikContext()

  const [dialogState, setDialogState] = useState(DIALOG_STATES.DIALOG_FORM)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [openEmptyCategoriesDialog, setOpenEmptyCategoriesDialog] = useState(false)
  const [option, setOption] = useState({})

  useEffect(() => {
    if (showDialog) setOpenEditDialog(true)

    return () => {
      if (showDialog) onClose()
    }
  }, [showDialog])

  const categoryOptions = categories.filter(
    (x) =>
      allowCategoriesInMultipleDistances ||
      !items.some(
        (i) => i.eventCategory.id === x.id && i.eventDistance.id !== selectedDistance.distance.id
      )
  )

  const distance = {
    ...selectedDistance.distance,
    quota: selectedDistance.distance.quota || '',
    categories:
      selectedDistance.categories.reduce(
        (acc, x) => ({
          ...acc,
          [x.name]: x
        }),
        {}
      ) || {}
  }

  const hasBindCategory = formState.ticketTypes.some((x) =>
    x.items.some((i) =>
      i.inscriptionFormItem && formState.associatedBy === OPTIONS.Distance.key
        ? distance.id === i.inscriptionFormItem.eventDistanceId
        : !!distance.categories[i.inscriptionFormItem.eventCategory?.name]
    )
  )

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('El nombre de la distancia es requerido')
      .trim()
      .max(100, 'El nombre de la distancia no puede exceder de 100 caracteres')
      .test(
        'unique',
        'El nombre de la distancia ya existe',
        (value) => !asyncError || value !== asyncError.name
      ),

    distance: Yup.number()
      .typeError('Ingrese un número válido')
      .required('La distancia es requerida')
      .positive('Ingrese un valor mayor a 0')
      .integer('Ingrese un número entero')
      .max(99999, 'No puede exceder la distancia máxima permitida'),
    quota: Yup.number()
      .typeError('Ingrese un número válido')
      .integer('Ingrese un número entero')
      .max(99999999, 'Se puede ingresar hasta 8 dígitos')
      .test(
        'test-negative-quota',
        'El cupo no puede ser un número negativo',
        (value = 0) => +value >= 0
      )
      .test(
        'test-min-quota',
        'El cupo distancia debe ser mayor al total de cupos por categoría',
        (value, context) => {
          if (!value) return true

          const quotaCategories = Object.values(context.parent.categories)
            .filter(Boolean)
            .reduce((acc, e) => acc + (e.quota || 0), 0)

          return value >= quotaCategories
        }
      )
      .test('test-min-quota', 'error', (value = 0, { createError, parent }) => {
        const hasError = value !== 0 && value < parent.inscriptionsCount
        return (
          !hasError ||
          createError({
            message: `El cupo debe ser mayor o igual al número de inscriptos (${parent.inscriptionsCount})`
          })
        )
      }),
    categories: Yup.lazy((value) =>
      Object.values(value).filter(Boolean).length
        ? Yup.object().shape(generateValidation(value))
        : Yup.object()
    )
  })

  const handleChangeCategory = (option, setValues, values) => (e) => {
    const checked = e?.target.checked
    const categoryList = Object.values(values.categories).filter(Boolean)

    const error =
      !checked &&
      dialogState === DIALOG_STATES.DIALOG_FORM &&
      formState.ticketTypes.some((x) =>
        x.items.some(
          (i) =>
            i.inscriptionFormItem.eventDistanceId === values.id &&
            i.inscriptionFormItem.eventCategoryId === option.id
        )
      )

    if (error) {
      const conditional =
        formState.associatedBy !== OPTIONS.Distance.key
          ? !formState.items.some(
              (item) => item.eventDistanceId !== values.id && item.eventCategoryId === option.id
            )
          : formState.items.filter(
              (x) =>
                categoryList.some((c) => c.id === x.eventCategoryId) &&
                x.eventDistanceId === values.id
            ).length === 1

      const lastCategoryBind = conditional
        ? DIALOG_STATES.DIALOG_NOTIFY
        : DIALOG_STATES.DIALOG_UNBIND_NOTIFY

      setDialogState(lastCategoryBind)
      setOption(option)

      return
    }

    const category = {
      ...option,
      quota: option.quota || ''
    }

    const categories = {
      ...values.categories,
      [option.name]: checked ? category : null
    }

    setDialogState(DIALOG_STATES.DIALOG_FORM)
    setValues({ ...values, categories })
  }

  const handleAllSelectOptions = (setFieldValue) => {
    const allOptions = categoryOptions.reduce(
      (acc, category) => ({ ...acc, [category.name]: category }),
      {}
    )
    setFieldValue('categories', allOptions)
  }

  const handleAllRemoveOptions = (setFieldValue) => setFieldValue('categories', {})

  const updatedItems = (items, distanceId, otherItems) =>
    formState.associatedBy === OPTIONS.Distance.key
      ? [
          ...items.filter((i) => i.inscriptionFormItem.eventDistanceId !== distanceId),

          ...otherItems
            .filter((x) =>
              items.some((i) => i.inscriptionFormItem?.eventDistanceId === x.eventDistanceId)
            )
            .map((inscriptionFormItem) => ({ inscriptionFormItem }))
        ]
      : [
          ...items.filter(
            (i) => i.inscriptionFormItem && i.inscriptionFormItem.eventDistanceId !== distanceId
          ),

          ...otherItems
            .filter((x) =>
              items.some((i) => i.inscriptionFormItem.eventCategoryId === x.eventCategoryId)
            )
            .map((inscriptionFormItem) => ({ inscriptionFormItem }))
        ]

  const onSubmit = async ({ id, categories, distance, name, quota }, { setErrors }) => {
    const categoryList = Object.values(categories).filter(Boolean)

    if (!categoryList.length) {
      setOpenEmptyCategoriesDialog(true)
      return
    }

    if (dialogState === DIALOG_STATES.DIALOG_FORM) {
      setDialogState(DIALOG_STATES.DIALOG_CONFIRM)
      return
    }

    const distanceModel = {
      name: name.trim(),
      distance: Number(distance),
      id,
      quota: Number(quota)
    }

    const model = await dispatch(updateDistance(eventId, distanceModel))
    if (model) {
      const updateItems = categoryList.map((c) => ({
        eventDistance: model,
        eventDistanceId: model.id,
        eventCategory: c,
        eventCategoryId: c.id,
        inscriptionsCount: c.inscriptionsCount || 0,
        validateGender: formState.validateGender,
        validateAge: formState.validateAge,
        quota: Number(c.quota) || 0
      }))

      const updatedTicketTypes = ticketTypes.map((x) => ({
        ...x,
        items: updatedItems(x.items, model.id, updateItems)
      }))

      const excludedItemsFor = items.filter((x) => x.eventDistanceId !== model.id)
      await dispatch(loadDistances(eventId))

      showSnackbarSuccess('¡Distancia actualizada con éxito!')

      setValues((values) => ({
        ...values,
        items: [...excludedItemsFor, ...updateItems],
        ticketTypes: updatedTicketTypes
      }))

      return true
    } else {
      setErrors({
        name: 'El nombre de la distancia ya existe'
      })
      setDialogState(false)
    }
  }

  const handleOnClose = () => {
    onClose()
    setOpenEditDialog(false)
  }

  const getTitle = () => {
    if (dialogState === DIALOG_STATES.DIALOG_FORM)
      return duplicateMode ? 'DISTANCIA DUPLICADA' : 'EDITAR DISTANCIA'

    return 'NOTIFICACIÓN'
  }

  return (
    <>
      <IconButton color='primary' onClick={() => setOpenEditDialog(true)} title='Editar distancia'>
        <EditOutlinedIcon />
      </IconButton>
      <ActionDialog
        open={openEditDialog}
        values={distance}
        action={onSubmit}
        title={getTitle()}
        validationSchema={validationSchema}
        className={clsx(
          classes.dialog,
          dialogState !== DIALOG_STATES.DIALOG_FORM && 'confirmDialog'
        )}
        formClassName={clsx(
          classes.formContainer,
          (dialogState !== DIALOG_STATES.DIALOG_FORM ||
            dialogState !== DIALOG_STATES.DIALOG_CONFIRM) &&
            'alert'
        )}
        onClose={dialogState === DIALOG_STATES.DIALOG_FORM ? handleOnClose : null}>
        {({
          handleChange,
          handleBlur,
          errors,
          touched,
          values,
          setFieldValue,
          isValid,
          isSubmitting,
          setValues
        }) => {
          const someSeletedOption = Object.values(values.categories).filter(Boolean).length

          return (
            <>
              {dialogState === DIALOG_STATES.DIALOG_FORM && (
                <div className={classes.sectionContainer}>
                  <div className={classes.fieldContainer}>
                    <div className={classes.formGroup}>
                      <Typography color='primary' variant='caption'>
                        Nombre*
                      </Typography>
                      <TextField
                        variant='outlined'
                        value={values.name}
                        name='name'
                        helperText={errors.name}
                        autoComplete='off'
                        error={touched.name && !!errors.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        size='small'
                        fullWidth
                        color='primary'
                        placeholder='Nombre de la nueva distancia'
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <Typography variant='caption' color='primary'>
                        Distancia*
                      </Typography>
                      <NumericField
                        variant='outlined'
                        size='small'
                        color='primary'
                        name='distance'
                        value={values.distance}
                        helperText={errors.distance}
                        error={touched.distance && !!errors.distance}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        placeholder='Distancia en metros'
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <Typography variant='caption' color='primary'>
                        Cupo distancia (opcional)
                      </Typography>
                      <NumericField
                        variant='outlined'
                        size='small'
                        fullWidth
                        color='primary'
                        name='quota'
                        value={values.quota}
                        helperText={errors.quota}
                        className={classes.distanceQuota}
                        error={touched.quota && !!errors.quota}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete='off'
                        placeholder='Ej: 2000'
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <CustomizedTooltip
                                position='bottom'
                                isTopMost
                                title='La sumatoria de cupo por distancia debe ser inferior o igual al cupo total del evento.'>
                                {({ handleTooltip }) => (
                                  <IconButton onClick={handleTooltip}>
                                    <InfoIcon color='primary' />
                                  </IconButton>
                                )}
                              </CustomizedTooltip>
                            </InputAdornment>
                          )
                        }}
                      />
                    </div>
                  </div>
                  {!!categoryOptions.length && (
                    <>
                      <Typography
                        color='primary'
                        variant='h6'
                        align='center'
                        className={classes.underlineText}>
                        Asociar con las siguientes categorías
                      </Typography>
                      <div className={classes.subtitleContainer}>
                        <Typography
                          color='primary'
                          variant='caption'
                          align='right'
                          className={classes.labelQuota}>
                          Cupo x categoría (opcional)
                        </Typography>
                        {!someSeletedOption ? (
                          <Typography
                            color='primary'
                            variant='h6'
                            onClick={() => handleAllSelectOptions(setFieldValue)}
                            className={classes.textLink}>
                            Seleccionar todas
                          </Typography>
                        ) : (
                          !hasBindCategory && (
                            <Typography
                              color='primary'
                              variant='h6'
                              onClick={() => handleAllRemoveOptions(setFieldValue)}
                              className={classes.textLink}>
                              Limpiar selección
                            </Typography>
                          )
                        )}
                      </div>
                    </>
                  )}
                  {!categoryOptions.length && (
                    <div className={classes.emptyMessageContainer}>
                      <ErrorIcon color='error' className={classes.errorIcon} />
                      <Typography color='error' variant='h6'>
                        No hay categorías disponibles para asociar esta distancia. Para poder
                        asociarla crea una nueva categoría o activa el switch que permite asociar
                        categorías a más de una distancia.
                      </Typography>
                    </div>
                  )}
                  {categoryOptions.map((c) => (
                    <div
                      className={clsx(
                        classes.checkContainer,
                        !values.categories[c.name] && classes.optionDisabled
                      )}
                      key={c.id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color='primary'
                            checked={!!values.categories[c.name]}
                            onChange={handleChangeCategory(c, setValues, values)}
                            name={c.name}
                          />
                        }
                        title={c.name}
                        classes={{ label: classes.label }}
                        className={classes.formControl}
                        label={c.name}
                      />
                      <Typography className={classes.label} title={c.shortName}>
                        {c.shortName}
                      </Typography>
                      <div className={clsx(classes.formGroup, classes.quota)}>
                        <NumericField
                          variant='outlined'
                          color='primary'
                          size='small'
                          name={`categories.${c.name}.quota`}
                          onBlur={handleBlur}
                          disabled={!values.categories[c.name]}
                          errors={
                            touched.categories &&
                            touched.categories[c.name]?.quota &&
                            errors.categories &&
                            errors.categories[c.name] &&
                            errors.categories[c.name]?.quota
                          }
                          helperText={errors.categories && errors.categories[c.name]?.quota}
                          placeholder='Ej: 2000'
                          onChange={handleChange}
                          value={values.categories[c.name] ? values.categories[c.name].quota : ''}
                        />
                      </div>
                    </div>
                  ))}

                  <div className={classes.buttonContainer}>
                    <Button
                      color='primary'
                      variant='contained'
                      type='submit'
                      endIcon={
                        updateDistancePending && <CircularProgress size={16} color='primary' />
                      }
                      className={classes.button}
                      disabled={!isValid || isSubmitting}>
                      Guardar
                    </Button>
                  </div>
                  <EmptyCategoriesNotifyDialog
                    open={openEmptyCategoriesDialog}
                    message='La distancia necesita estar asociada con al menos una categoría de tu evento.'
                    onClose={() => setOpenEmptyCategoriesDialog(false)}
                  />
                </div>
              )}

              {dialogState === DIALOG_STATES.DIALOG_CONFIRM && (
                <div className={classes.messageContainer}>
                  <Typography color='primary' variant='h6' align='center'>
                    El cambio realizado en la distancia se aplicará en todas las categorías
                    asociadas.
                  </Typography>
                  <div className={classes.actionButtonContainer}>
                    <Button
                      color='primary'
                      variant='contained'
                      className={classes.actionButton}
                      onClick={() => setDialogState(DIALOG_STATES.DIALOG_FORM)}>
                      Cancelar
                    </Button>
                    <Button
                      color='primary'
                      variant='contained'
                      className={classes.actionButton}
                      endIcon={
                        updateDistancePending && <CircularProgress size={16} color='primary' />
                      }
                      disabled={updateDistancePending}
                      type='submit'>
                      Aplicar
                    </Button>
                  </div>
                </div>
              )}

              {dialogState === DIALOG_STATES.DIALOG_NOTIFY && (
                <div className={classes.alertContainer}>
                  <Typography color='primary' variant='h6' align='center'>
                    <strong>
                      {option.name} {values.name}
                    </strong>
                    &nbsp;no se puede eliminar porque es la única asociada a uno o más tickets, para
                    eliminarla debes modificar primero el ticket.
                  </Typography>

                  <Button
                    color='primary'
                    variant='contained'
                    className={classes.button}
                    onClick={() => setDialogState(DIALOG_STATES.DIALOG_FORM)}>
                    Aceptar
                  </Button>
                </div>
              )}

              {dialogState === DIALOG_STATES.DIALOG_UNBIND_NOTIFY && (
                <div className={classes.unbindNotifyContainer}>
                  <Typography variant='h6' color='primary' align='center'>
                    <strong>
                      {option.name} {values.name}
                    </strong>
                    &nbsp;se encuentra asociado a uno o más tickets creados, al deshabilitarlo se
                    borrará de todos los tickets en los que figure.
                    <br />
                    ¿Deseas continuar?
                  </Typography>
                  <div className={classes.alertButtonContainer}>
                    <Button
                      color='primary'
                      variant='contained'
                      className={classes.button}
                      onClick={() => setDialogState(DIALOG_STATES.DIALOG_FORM)}>
                      Cancelar
                    </Button>
                    <Button
                      color='primary'
                      variant='contained'
                      className={classes.button}
                      onClick={() => handleChangeCategory(option, setValues, values)()}>
                      Continuar
                    </Button>
                  </div>
                </div>
              )}
            </>
          )
        }}
      </ActionDialog>
    </>
  )
}

export default EditDistanceDialog
