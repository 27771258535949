import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.7),

    [theme.breakpoints.up('lg')]: {
      '&.alt': {
        backgroundColor: '#969696 !important'
      },
      display: 'flex'
    }
  },
  featureImageContainerMobile: {
    maxHeight: 620,
    height: '48vw',
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  featureImageContainer: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      minWidth: 827,
      width: '60%',
      height: '29vw',
      maxHeight: 537,
      minHeight: 403,
      flexShrink: 0,
      maxWidth: 1102
    }
  },
  featureImage: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    transition: 'opacity ease-in-out 400ms',
    backgroundSize: 'cover',
    margin: '0 auto',
    height: '100%',
    minWidth: '100%'
  },
  panel: {
    color: theme.palette.primary.contrastText,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      justifyContent: 'space-between'
    }
  },
  eventInfoContainer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    flexDirection: 'column',

    '&.alt': {
      backgroundColor: '#969696 !important'
    },

    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      background: 'transparent'
    }
  },
  date: {
    fontWeight: 500,
    fontSize: '1.2rem',
    padding: theme.spacing(2),
    flexShrink: 0,
    textTransform: 'uppercase',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 6)
    }
  },

  titleContainer: {
    flexGrow: 1,
    display: 'flex',
    width: '100%',
    padding: theme.spacing(3, 2),
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: theme.spacing(0, 6)
    }
  },
  title: {
    color: '#ffffff',
    fontWeight: 500,
    fontSize: '1rem',
    textAlign: 'center',

    '&.results': {
      fontWeight: 600,
      fontSize: '1.375rem'
    },

    [theme.breakpoints.up('lg')]: {
      fontSize: '1.3rem',
      textAlign: 'left'
    }
  },
  eventButtonBlock: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(3),

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row-reverse',
      padding: theme.spacing(0, 6),
      marginBottom: 0
    }
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4)
  },

  enableInscriptionButton: {
    borderRadius: 10,
    padding: theme.spacing(1.5, 4),
    width: 234,
    height: 48,
    '& span': {
      color: theme.palette.primary.main,
      fontWeight: 500,
      fontSize: '1rem'
    }
  },

  downloadEventResultsButton: {
    borderRadius: 10,
    width: 240,
    height: 48,
    '& span': {
      color: theme.palette.primary.main,
      fontWeight: 500,
      fontSize: '1rem'
    }
  },
  inscriptionStateButton: {
    borderRadius: 10,
    padding: theme.spacing(1.5),
    height: 48,
    width: 234,
    '& span': {
      color: theme.palette.secondary.main,
      fontWeight: 500,
      fontSize: '1rem'
    }
  },
  resultButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing()
  },
  downloadResultsLink: {
    fontWeight: 500,
    textDecoration: 'underline',
    fontSize: '.9rem',
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    [theme.breakpoints.up('lg')]: {
      width: 180
    }
  },
  inscriptionLink: {
    fontWeight: 500,
    textDecoration: 'underline',
    fontSize: '1rem',
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    marginTop: -theme.spacing(2)
  },
  actionBarButtonContainer: {
    display: 'flex',
    flexGrow: 1,
    minHeight: 50,
    alignItems: 'center',

    '&.hidden': {
      visibility: 'hidden'
    },

    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end',
      marginRight: theme.spacing(3)
    }
  },
  actionButton: {
    border: 'none',
    '&:hover': {
      border: 'none'
    },
    '&:disabled': {
      border: 'none',
      color: theme.palette.primary.main
    },
    [theme.breakpoints.up('lg')]: {
      width: 'auto'
    }
  },
  actionButtonLabel: {
    display: 'flex',
    color: theme.palette.primary.contrastText,
    '& em': {
      fontSize: '1.25em',
      fontStyle: 'normal',
      marginLeft: theme.spacing(1)
    },

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      '& span': {
        marginLeft: theme.spacing(1)
      }
    }
  },
  shareButton: {
    color: theme.palette.primary.contrastText
  }
}))
