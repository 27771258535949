import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Button, CircularProgress, Typography, useMediaQuery } from '@material-ui/core'
import { Favorite as FavoriteIcon, FavoriteBorder as FavoriteBorderIcon } from '@material-ui/icons'
import { ReactComponent as AddCalendarIcon } from 'assets/icons/add-calendar-icon.svg'
import { ReactComponent as RemoveCalendarIcon } from 'assets/icons/remove-calendar-icon.svg'
import userImage from 'assets/img/portada.png'
import noImage from 'assets/img/portada-medidas.jpg'
import noImageMobile from 'assets/img/portada-prederminada-mobile.jpg'
import clsx from 'clsx'
import moment from 'moment'
import { ROUTES } from 'routes'
import { ConfirmDialog, EditableImage, ShareUrlMenuDialog } from 'shared'
import { verifyUserNotLogged } from 'state/modules/app'
import {
  deleteImage as deleteImageEvent,
  deleteResults,
  downloadResultsReport,
  downloadResultsSheet,
  load,
  reactEvent,
  setImage as updateImageEvent,
  updateCalendar,
  updateEvent,
  uploadResults
} from 'state/modules/events'
import { STRING_EMPTY } from 'utils/constants'
import { getDateEventFormat } from 'utils/functions'
import { showSnackbarError } from 'utils/snackbar'

import { CheckStateInscriptionDialog } from '../CheckStateInscriptionDialog'
import { CreateInscriptionDialog } from '../CreateInscriptionDialog'
import { EventResultsDialog } from '../EventResultsDialog'
import { EventModalityDialog } from '../ModalityEventDialog'
import { InscriptionOptionsDialog } from '../OptionsInscriptionDialog'
import { SignServiceLetterDialog } from '../SignServiceLetterDialog'
import { StateInscriptionDialog } from '../StateInscriptionDialog'
import { UpdateConfirmDialog } from '../UpdateConfirmDialog'
import { UploadResultsDialog } from '../UploadResultsDialog'

import { useStyles } from './HeaderProfile.style'

const IMAGE_TYPE = {
  PROFILE_IMAGE: 'profileImage',
  DEFAULT_IMAGE: 'defaultImage'
}

const DIALOG_TITLE = (
  <Typography variant='h6'>
    Tenés que tener una cuenta de <strong>Sportmetric</strong>&nbsp; para poder inscribirte en este
    evento.
    <br />
    <br />
    Para este y más beneficios:
  </Typography>
)

const HeaderProfile = ({ handleSave, canEdit, inProgressEvent }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const { slugUrlOrId } = useParams()

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const {
    event,
    deleteResultsPending,
    downloadResultsSheetPending,
    downloadResultsReportPending,
    eventResultsReportUrlPending,
    updateCalendarPending,
    pendingReact
  } = useSelector((state) => state.events)
  const { user } = useSelector((state) => state.auth)

  const {
    loggedUserMetadata,
    id,
    from,
    to,
    name,
    profileImages,
    externalInscriptionUrl,
    activeInscriptionFormId,
    activeInscriptionForm,
    slugUrl,
    isSuspended,
    updatedAt,
    createdAt,
    organization: eventOwner,
    hasResults,
    hasPartialResults,
    title,
    hasResultsSheetFile,
    externalResultsUrl,
    likesCount
  } = event

  const [openUpdateConfirmDialog, setOpenUpdateConfirmDialog] = useState(false)
  const [openCreateInscriptionDialog, setOpenCreateInscriptionDialog] = useState(false)
  const [openEventModalityDialog, setOpenEventModalityDialog] = useState(false)
  const [openSignServiceLetter, setOpenSignServiceLetter] = useState(false)
  const [openUploadResultsDialog, setOpenUploadResultsDialog] = useState(false)
  const [openInscriptionOptionsDialog, setOpenInscriptionOptionsDialog] = useState(false)
  const [openCheckStateInscriptionDialog, setOpenCheckStateInscriptionDialog] = useState(false)
  const [openDialogResults, setOpenDialogResults] = useState(false)
  const [openStateDialog, setOpenStateDialog] = useState(false)
  const [openEventResultsDialog, setOpenEventResultsDialog] = useState(false)

  const getImageUrl = (type = IMAGE_TYPE.PROFILE_IMAGE) => {
    const defaultImage = isMobile ? noImageMobile : noImage
    const viewImage = canEdit ? defaultImage : userImage

    if (type === IMAGE_TYPE.PROFILE_IMAGE)
      return Array.isArray(profileImages) && profileImages.length > 0 ? profileImages[0] : viewImage
    else return viewImage
  }

  const handleSaveImage = ({ value }) => dispatch(updateImageEvent(id, value))

  const handleDeleteImage = () => dispatch(deleteImageEvent(event))

  const handleSaveSlugUrl = async (values) => {
    const eventUpdate = { ...event, ...values }

    const eventData = await dispatch(updateEvent(eventUpdate))

    history.replace(`${ROUTES.EVENTS.PROFILE}/${eventData.slugUrl}`)
  }

  const handleAuthetication = (action, redirectUrl) => (e) => {
    if (user) {
      action(e)
      return
    }

    if (!activeInscriptionForm?.allowUnregisteredUsers) {
      const verifyUserNotLoggedParams = {
        state: true,
        redirectUrl,
        dialogSubTitle: DIALOG_TITLE
      }

      dispatch(verifyUserNotLogged(verifyUserNotLoggedParams))
    } else {
      setOpenInscriptionOptionsDialog(true)
    }
  }

  const redirectExternalResultsUrl = () => window.open(event.externalResultsUrl, '_blank')

  const handleEventResults = async () => {
    if (externalResultsUrl) {
      redirectExternalResultsUrl()
    } else if (!hasResultsSheetFile) {
      setOpenEventResultsDialog(true)
    } else {
      dispatch(downloadResultsReport(event))
    }
  }

  const handleUploadResults = (isPartialResults) => async (e) => {
    if (e.target.files.length === 0) return

    const file = e.target.files[0]
    const validResultFormats = [
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ]

    e.target.value = ''

    if (!validResultFormats.includes(file.type)) {
      showSnackbarError({
        message: 'Los resultados del evento deben ser en formato excel/pdf'
      })
      return
    }

    await dispatch(uploadResults(id, file, isPartialResults))
    await dispatch(load(slugUrlOrId))
    setOpenUploadResultsDialog(false)
  }

  const fromLocal = moment(from)
  const toLocal = moment(to)
  const afterEvent = toLocal < moment()
  const eventDateFormat = getDateEventFormat(fromLocal, toLocal)
  const inscriptionFormUrl = `/events/${slugUrl}/inscription`
  const listInscriptionUrl = `/events/${slugUrl}/list-inscription`

  const handleRedirectEventForm = () => history.push(inscriptionFormUrl)
  const handleRedirectExternalInscription = () => window.open(externalInscriptionUrl, '_blank')
  const handleRedirectListInscription = () => history.push(listInscriptionUrl)

  const tryDeleteResults = async () => {
    if (event.externalResultsUrl) {
      handleSave({ value: { externalResultsUrl: STRING_EMPTY, hasPartialResults: false } })
      return
    }

    await dispatch(deleteResults(event.id))
    await dispatch(load(event.id))
  }

  const handleRedirectInscriptions = () => {
    if (externalInscriptionUrl) {
      handleRedirectExternalInscription()
      return
    }

    if (activeInscriptionFormId) handleRedirectListInscription()
  }

  const handleDownloadResults = (action) => {
    if (action && user) {
      action(event)
    } else {
      dispatch(
        verifyUserNotLogged({
          state: true
        })
      )
    }
  }

  const handleActionEvent = (action) => {
    if (!user) {
      dispatch(verifyUserNotLogged({ state: true }))
    } else {
      dispatch(action(event))
    }
  }

  const handleOpenInscriptionStateDialog = () => {
    if (!user) {
      dispatch(verifyUserNotLogged({ state: true }))
    } else {
      setOpenStateDialog(true)
    }
  }

  const renderEventButtons = () => {
    const isEventOwner = eventOwner.loggedUserMetadata?.canEdit
    const enableInscription =
      !afterEvent &&
      !hasResults &&
      (!!externalInscriptionUrl ||
        (!!activeInscriptionForm &&
          !activeInscriptionForm.isDraft &&
          moment().isBetween(
            moment(activeInscriptionForm.openFrom),
            moment(activeInscriptionForm.openTo),
            'YYYY-MM-DD'
          ))) &&
      (!event.mercadoPagoCode ||
        event.mercadoPagoEnabled ||
        !event.activeInscriptionForm.ticketTypes.every(
          (x) =>
            x.mercadoPagoEnabled &&
            !x.paymentLocationsEnabled &&
            !x.bankAccountsEnabled &&
            !x.paymentUrl
        ) ||
        activeInscriptionForm.isFree)

    const renderEnableInscriptionButton =
      !externalInscriptionUrl &&
      !activeInscriptionFormId &&
      !afterEvent &&
      isEventOwner &&
      canEdit &&
      !hasResults

    const renderUploadEventResultsButton =
      canEdit && fromLocal.isBefore(moment()) && !hasResults && !isSuspended

    const renderInscriptionButton = !canEdit && enableInscription

    const renderInscriptionStateButton =
      !canEdit &&
      !afterEvent &&
      !!activeInscriptionFormId &&
      !activeInscriptionForm?.isDraft &&
      activeInscriptionForm?.allInscriptionsCount > 0

    const renderInscriptionsButton =
      canEdit &&
      (!!externalInscriptionUrl || (!!activeInscriptionForm && !activeInscriptionForm.isDraft))

    const renderEditInscriptionFormButton =
      canEdit &&
      isEventOwner &&
      fromLocal.isAfter(moment()) &&
      !!activeInscriptionForm &&
      !activeInscriptionForm.isDraft

    const renderLabelInscriptions =
      !canEdit &&
      !hasResults &&
      !afterEvent &&
      !!activeInscriptionForm &&
      moment().isSameOrAfter(moment(activeInscriptionForm.openFrom).format('YYYY-MM-DD')) &&
      !!activeInscriptionForm.showInscriptionsFrom &&
      !activeInscriptionForm.isDraft &&
      activeInscriptionForm.inscriptionsCount >= activeInscriptionForm.showInscriptionsFrom

    const renderDownloadEventResults = hasResults && !canEdit

    const renderDeleteEventResultsButton = hasResults && canEdit

    const renderDownloadEventResultsLink = hasResults && hasResultsSheetFile && !isMobile

    const renderInscriptionFormDraftButton =
      canEdit && !!activeInscriptionForm && activeInscriptionForm.isDraft && !afterEvent

    const getResultsTitleButton = () => {
      if (hasResultsSheetFile) {
        if (hasPartialResults) return 'Descarga result. parciales'
        else return 'Descargar resultados'
      } else {
        if (hasPartialResults) return 'Ver resultados parciales'
        else return 'Ver resultados'
      }
    }

    return (
      <div className={classes.eventButtonBlock}>
        <div className={classes.buttonsContainer}>
          {renderEnableInscriptionButton && (
            <Button
              variant='contained'
              color='secondary'
              onClick={() => setOpenCreateInscriptionDialog(true)}
              className={classes.enableInscriptionButton}>
              Habilitar inscripción
            </Button>
          )}

          {renderInscriptionButton && (
            <Button
              variant='contained'
              color='secondary'
              onClick={
                activeInscriptionFormId
                  ? handleAuthetication(handleRedirectEventForm, inscriptionFormUrl)
                  : handleRedirectExternalInscription
              }
              className={classes.enableInscriptionButton}>
              {!loggedUserMetadata?.hasActiveInscription ? 'Inscribirme' : 'Inscripción'}
            </Button>
          )}

          {renderDownloadEventResults && (
            <Button
              variant='contained'
              color='secondary'
              onClick={handleEventResults}
              disabled={downloadResultsReportPending || eventResultsReportUrlPending}
              endIcon={
                (downloadResultsReportPending || eventResultsReportUrlPending) && (
                  <CircularProgress size={16} color='primary' />
                )
              }
              className={classes.downloadEventResultsButton}>
              {getResultsTitleButton()}
            </Button>
          )}

          {renderInscriptionStateButton && (
            <Button
              variant='outlined'
              color='secondary'
              onClick={() => setOpenCheckStateInscriptionDialog(true)}
              className={classes.inscriptionStateButton}>
              Estado de mi inscripción
            </Button>
          )}

          {renderLabelInscriptions && (
            <Typography
              className={classes.inscriptionLink}
              variant='h6'
              onClick={handleOpenInscriptionStateDialog}
              align='center'>
              Ver inscriptos
            </Typography>
          )}

          {renderInscriptionsButton && (
            <Button
              variant='contained'
              color='secondary'
              onClick={handleRedirectInscriptions}
              className={classes.enableInscriptionButton}>
              Inscripciones
            </Button>
          )}

          {renderEditInscriptionFormButton && (
            <Button
              variant='outlined'
              color='secondary'
              component={Link}
              to={`${ROUTES.EVENTS.INSCRIPTION}/${slugUrl}`}
              className={classes.inscriptionStateButton}>
              Editar Formulario
            </Button>
          )}

          {renderUploadEventResultsButton && (
            <Button
              variant='outlined'
              color='secondary'
              onClick={() => setOpenUploadResultsDialog(true)}
              className={classes.inscriptionStateButton}>
              Cargar Resultados
            </Button>
          )}

          {renderDeleteEventResultsButton && (
            <div className={classes.resultButtonContainer}>
              <Button
                disabled={deleteResultsPending}
                className={classes.inscriptionStateButton}
                endIcon={deleteResultsPending && <CircularProgress size={16} color='secondary' />}
                variant='outlined'
                color='secondary'
                onClick={() => setOpenDialogResults(true)}>
                Quitar resultados
              </Button>

              {renderDownloadEventResultsLink && (
                <Typography
                  color='primary'
                  align='center'
                  disabled={downloadResultsSheetPending}
                  onClick={() => handleDownloadResults(() => dispatch(downloadResultsSheet(event)))}
                  className={classes.downloadResultsLink}>
                  Descargar resultados
                </Typography>
              )}
            </div>
          )}

          {renderInscriptionFormDraftButton && (
            <Button
              variant='contained'
              color='secondary'
              to={`${ROUTES.EVENTS.INSCRIPTION}/${slugUrl}`}
              component={Link}
              className={classes.enableInscriptionButton}>
              Borrador Formulario
            </Button>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className={clsx(classes.header, afterEvent && 'alt')}>
      <EditableImage
        entity={event}
        mode='button'
        field='profileImages'
        onSave={handleSaveImage}
        defaultImage={getImageUrl(IMAGE_TYPE.DEFAULT_IMAGE)}
        canEdit={canEdit && !inProgressEvent}
        isSuspended={event.isSuspended}
        className={classes.featureImageContainer}
        inputClassName={classes.featureImage}
        onDelete={handleDeleteImage}>
        <div className={classes.featureImageContainer}>
          <div
            className={classes.featureImage}
            style={{ backgroundImage: `url(${getImageUrl()})` }}
          />
        </div>
      </EditableImage>
      <div className={classes.panel}>
        <div className={clsx(classes.eventInfoContainer, afterEvent && 'alt')}>
          <div className={classes.date}>{eventDateFormat}</div>

          <EditableImage
            className={classes.featureImageContainerMobile}
            canEdit={canEdit && !inProgressEvent}
            inputClassName={classes.featureImage}
            defaultImage={getImageUrl(IMAGE_TYPE.DEFAULT_IMAGE)}
            entity={event}
            field='profileImages'
            isSuspended={event.isSuspended}
            mode='button'
            onSave={handleSaveImage}
            onDelete={handleDeleteImage}>
            <div className={classes.featureImageContainerMobile}>
              <div
                className={classes.featureImage}
                style={{ backgroundImage: `url(${getImageUrl()})` }}
              />
            </div>
          </EditableImage>

          <div className={clsx(classes.actionBarButtonContainer, canEdit && 'hidden')}>
            {!afterEvent && (
              <Button
                variant='outlined'
                color='primary'
                disabled={updateCalendarPending}
                className={classes.actionButton}
                classes={{ label: classes.actionButtonLabel }}
                onClick={() => handleActionEvent(updateCalendar)}
                title={
                  !loggedUserMetadata.inCalendar
                    ? 'Agregar a mi calendario'
                    : ' Quitar de mi calendario'
                }>
                {!loggedUserMetadata.inCalendar ? <AddCalendarIcon /> : <RemoveCalendarIcon />}
              </Button>
            )}
            <Button
              variant='outlined'
              color='primary'
              disabled={pendingReact}
              className={classes.actionButton}
              classes={{ label: classes.actionButtonLabel }}
              title={!loggedUserMetadata.like ? 'Me gusta' : 'Ya no me gusta'}
              onClick={() => handleActionEvent(reactEvent)}>
              {loggedUserMetadata.like ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              <em>{likesCount}</em>
            </Button>
            <ShareUrlMenuDialog
              id={id}
              type='event'
              isHiddenLabel
              isOutlineIcon
              updatedAt={updatedAt || createdAt}
              copyMessage='El link del evento fue copiado al portapapeles'
              suspended={isSuspended}
              slugUrl={slugUrl}
              title={title}
              buttonClassName={classes.shareButton}
            />
          </div>
        </div>

        <UpdateConfirmDialog
          title='Estás modificando el nombre del Evento'
          open={openUpdateConfirmDialog}
          message={` Al confirmar el cambio de nombre de tu evento 
                    se modificará el link del evento, si ya compartiste el link anterior tené en cuenta que el
                    mismo ya no estará vigente.`}
          onSave={handleSaveSlugUrl}
          onClose={() => setOpenUpdateConfirmDialog(false)}
        />

        {(!isMobile || !hasResults || canEdit) && (
          <>
            <div className={classes.titleContainer}>
              <Typography variant='h2' className={classes.title} color='primary'>
                {name}
              </Typography>
            </div>

            {renderEventButtons()}
          </>
        )}

        <UploadResultsDialog
          title='Carga de resultados'
          open={openUploadResultsDialog}
          onUploadResults={handleUploadResults}
          onSave={handleSave}
          eventId={id}
          afterEvent={afterEvent}
          load={() => dispatch(load(slugUrlOrId))}
          onClose={() => setOpenUploadResultsDialog(false)}
        />

        <CreateInscriptionDialog
          open={openCreateInscriptionDialog}
          eventSlugUrl={slugUrl}
          title='Crear formulario'
          onSave={handleSave}
          onOpenModalityEventDialog={() => setOpenEventModalityDialog(true)}
          onClose={() => setOpenCreateInscriptionDialog(false)}
        />

        <EventModalityDialog
          open={openEventModalityDialog}
          onClose={() => setOpenEventModalityDialog(false)}
          setOpenSignServiceLetter={setOpenSignServiceLetter}
        />

        <SignServiceLetterDialog
          open={openSignServiceLetter}
          onClose={() => setOpenSignServiceLetter(false)}
        />

        <InscriptionOptionsDialog
          open={openInscriptionOptionsDialog}
          onClose={() => setOpenInscriptionOptionsDialog(false)}
          inscriptionFormUrl={inscriptionFormUrl}
        />

        <CheckStateInscriptionDialog
          open={openCheckStateInscriptionDialog}
          onClose={() => setOpenCheckStateInscriptionDialog(false)}
        />

        <ConfirmDialog
          openDialog={openDialogResults}
          message='Esta acción eliminará los resultados del evento.
                                    ¿Esta seguro que desea continuar?'
          setOpen={setOpenDialogResults}
          onAction={tryDeleteResults}
          type='delete'
        />

        <EventResultsDialog
          open={openEventResultsDialog}
          event={event}
          onClose={() => setOpenEventResultsDialog(false)}
        />

        {!!event.activeInscriptionForm && (
          <StateInscriptionDialog
            open={openStateDialog}
            title={name}
            inscriptionsCount={activeInscriptionForm?.inscriptionsCount || 0}
            onClose={() => setOpenStateDialog(false)}
          />
        )}
      </div>
    </div>
  )
}

export default HeaderProfile
